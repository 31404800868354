#division-messages {
	display: block;
	margin: 3.5rem auto;
	padding: 0 1rem;

	font-size: 1.1rem;
	letter-spacing: .25ch;
	line-height: 1.5rem;

	text-align: center;
}

#our-team-link {
	margin: 0 0 4rem 0;

	text-align: center;
}

#our-team-link > a {
	letter-spacing: normal;
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
}
