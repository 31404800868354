#contact-wrapper {
	display: flex;
	justify-content: center;
}

#contact-main {
	max-width: 64rem;
	margin: 3rem 0;
	padding: 0 1rem;

	font-size: .9rem;
}

#contact-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10rem, 13rem));
	gap: 2rem;
	margin: 3rem 0;
}

.contact-heading {
	margin: 0 0 2rem 0;
}

#contact-main .spacer {
	margin: 0 0 1rem 0;
}

#contact-address {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
	margin: 5rem 0 3rem 0;
}

#contact-address-text {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}
