a {
	font-size: .8rem;
	font-family: 'Urbanist', sans-serif;
	font-weight: 500;
	letter-spacing: .75ch;
	color: #112368;
}

#background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	pointer-events: none;
}

#background > img {
	max-width: 100%;
}

#layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.separator {
	flex-grow: 1;
}

#link {
	padding: 4.5rem .5rem;
	text-align: center;
}

#link > section {
	margin: 1.25rem 0;
}

#arrow {
	width: 53px;
	height: 19px;
	transform: rotate(180deg);
}

.animFadeIn {
	opacity: 0;
	display: inline-block;
	animation-name: animFadeIn;
	animation-duration: .75s;
	animation-timing-function: linear;
	animation-delay: 2.1s;
	animation-fill-mode: forwards;
}

.animSlideDown {
	display: inline-block;
	transform: translateY(-100%);
	animation-name: animSlideDown;
	animation-duration: .75s;
	animation-timing-function: linear;
	animation-delay: 2.5s;
	animation-fill-mode: forwards;
}

@keyframes animFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes animSlideDown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}
