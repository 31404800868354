#background-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;

	z-index: -1;

	pointer-events: none;
}

#background-video-wrapper {
	position: relative;
}

#background-video img, #background-video video, #background-video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	object-fit: cover;
	object-position: center;
}

.animFadeOut {
	animation-name: animFadeOut;
	animation-duration: 1s;
	animation-timing-function: linear;
	animation-fill-mode: forwards;
}

@keyframes animFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
