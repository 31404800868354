#navbar-drawer-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 24.5rem;
	height: 100vh;

	background: transparent;
	pointer-events: none;
	overflow: hidden;
	z-index: 1;
}

#navbar-drawer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 24.5rem;
	height: 100%;

	opacity: 0;
	background: #112368;
	pointer-events: all;

	transform: translateX(-101%);

	transition: transform .4s cubic-bezier(.47,0,.74,.71);
}

#navbar-drawer.open {
	opacity: 1;

	transform: translateX(0);
}

#navbar-button-close {
	display: block;
	position: absolute;
	z-index: 999999999999999999999999999;
	top: 1rem;
	right: 1rem;

	background: transparent;
	border: 0;

	color: #fff;
}

#navbar-button-close > svg {
	width: 2rem;
}

#navbar-drawer-list-link {
	margin: 0;
	padding: 0;
	list-style: none;

	text-align: center;
}

#navbar-drawer-list-link > li {
	margin: 2.25rem;
	padding: 0;
}

#navbar-drawer-list-link a {
	color : #fff;
	font-size: 1.25rem;
	font-weight: 900;
	letter-spacing: .25ch;
	text-transform: uppercase;
}

#navbar {
	position: fixed;
	height: 14vh;
	width: 100%;
	padding: 0 3rem;
	background: #112368;
	text-align: center;
	z-index: 0;
}

#navbar-button-wrapper {
	display: flex;
	gap: 1.25rem;
	flex-direction: row;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	padding: .9rem 3rem;

	font-size: .85rem;
	font-weight: 800;
	letter-spacing: .4ch;
	text-transform: uppercase;
}

#navbar-button-wrapper span {
	color: #FFF;
}

#navbar-button-open {
	display: block;
	background: transparent;
	border: 0;
	color: #fff;

	line-height: 0;

	cursor: pointer;
	pointer-events: all;
}

#navbar-button-open > svg {
	display: block;
	width: 2rem;
	height: 1.5rem;
}

#logo {
	width: clamp(81px, 20vw, 115px);
}
