#team-messages {
        display: block;
	max-width: 40rem;
        margin: 4rem auto;
        padding: 0 1rem;

        font-size: .9rem;
        letter-spacing: .25ch;
        line-height: 1.5rem;

        text-align: center;
}

.team-heading {
	margin: 4rem 0;
	padding: 0 1rem;

	letter-spacing: .15ch;
	font-size: 2rem;
	font-weight: 900;
	text-align: center;
	text-transform: uppercase;
}


#team-logo-wrapper {
	margin: 4rem 0;
	padding: 0 1rem;

	text-align: center;
}

#team-logo-wrapper > img {
	max-width: 100%;
}


#grid-person {
	display: grid;
	gap: 6rem;
	grid-template-columns: repeat(auto-fill, 12rem);
	justify-content: center;
	max-width: 53rem;
	margin: 2rem auto;
	padding: 0 2rem;
}

.team-person-wrapper {
	position: relative;
}

.team-person-img-wrapper, .team-person-title-wrapper, .team-person-name-wrapper {
	overflow: hidden;
}

.team-person-title-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	padding: .25rem;	

	transform-origin: left top;
	transform: rotate(-90deg) translateX(-100%);
}

.team-person-title-wrapper {
	font-size: .55rem;
	font-weight: 900;
	text-transform: uppercase;
}

.team-person-name {
	padding: .25rem;
	font-size: .75rem;
	font-weight: 700;
	text-align: right;
	text-transform: uppercase;
}
