.article {
	display: grid;
	grid-template-columns: 1fr 0.75fr;
	gap: 1.5rem;
	grid-template-areas: "thumb body";
	max-width: 63rem;
	margin: 5rem auto;
	padding: 5rem 1rem;

	text-align: right;
}

.article.flip {
	grid-template-columns: 0.75fr 1fr;
	grid-template-areas: "body thumb";

	text-align: left;
}

.article-thumb-wrapper {
	grid-area: thumb;
}

.article-thumb-wrapper img {
	width: 100%;
}

.article-thumb {
	width: 100%;
}

.article-body {
	grid-area: body;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.article-body img {
	width: 100%;
}


.article-body > p {
	font-size: .75rem;
	line-height: 1.2rem;
}

.see-more a {
	letter-spacing: .25ch;
	font-weight: 900;
	text-transform: uppercase;
}
