#grid-division {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12.25rem, 1fr));
    justify-content: center;
    align-items: center;
    grid-gap: 2rem 1rem;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1rem;
    text-align: center;
}

#grid-division img {
    width: 90%;
}