@import-normalize; /* bring in normalize.css styles */

*, *::before, *::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 100%;
	background: #FFF;
}

body {
	position: relative;
	padding: 0;
	font-size: 1rem;
	color: #000;

	font-family: 'Urbanist', sans-serif;
}

.display-none {
	display: none !important;
}

.uppercase {
	text-transform: uppercase;
}

.overflow-hidden {
	overflow: hidden;
}

a {
	color: #112368;
	text-decoration: none;
}
